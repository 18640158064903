import {Helmet} from "react-helmet";
import React from "react";

import Favicon from '../assets/i/favicon.png'

const SEO = () => <Helmet>
    <title>Kracks</title>
    <script type="text/javascript">{`(function (m, e, t, r, i, k, a
                ) { m[i] = m[i] || function () { (m[i].a = m[i].a || []).push(arguments); };
                    m[i].l = 1 * new Date();
                    (k = e.createElement(t)),
                    (a = e.getElementsByTagName(t)[0]),
                    (k.async = 1),
                    (k.src = r),
                    a.parentNode.insertBefore(k, a);
                })
                (
                    window,
                    document,
                    "script",
                    "https://mc.yandex.ru/metrika/tag.js",
                    "ym",
                    )
                    ;
                    ym(54606217, "init", {
                        clickmap: true,
                        trackLinks: true,
                        accurateTrackBounce: true,
                        webvisor: true,
                    })
                    ;`}</script>
    <noscript>
        {`<div><img src="https://mc.yandex.ru/watch/54606217" style="position:absolute;left:-9999px" alt=""/>
        </div>`}
    </noscript>
    <script async src="https://www.googletagmanager.com/gtag/js?id=UA-144548371-1"></script>
    <script>{`function gtag(){dataLayer.push(arguments)}window.dataLayer=window.dataLayer||[],gtag("js",new
                    Date),gtag("config","UA-144548371-1")`}
    </script>
    <script type="text/javascript">{`var _tmr = window._tmr || (window._tmr = []);
                _tmr.push({id:"3123081",type:"pageView",start:(new Date).getTime(),pid:"USER_ID"}),

                function (t, e, r){if(!t.getElementById(r)){var a=t.createElement("script");a.type="text/javascript",a.async=!0,a.id=r,a.src="https://top-fwz1.mail.ru/js/code.js";var n=function(){var e=t.getElementsByTagName("script")[0];e.parentNode.insertBefore(a,e)};"[object Opera]"==e.opera?t.addEventListener("DOMContentLoaded",n,!1):n()}}(document, window, "topmailru-code")`}</script>
    <noscript>
        {`<div><img src="https://top-fwz1.mail.ru/counter?id=3123081;js=na"
                  style="border:0;position:absolute;left:-9999px" alt="Top.Mail.Ru"/></div>`}
    </noscript>
    <script>{`!function(e,t,a,n,g){e[n] = e[n] || [],e[n].push({"gtm.start":(new Date).getTime(),event:"gtm.js"});var m=t.getElementsByTagName(a)[0],r=t.createElement(a);r.async=!0,r.src="https://www.googletagmanager.com/gtm.js?id=GTM-M2NVTJD",m.parentNode.insertBefore(r,m)}(window,document,"script","dataLayer")`}</script>
    <link rel="shortcut icon" href={Favicon} type="image/png"/>
        <script>{`!(function(f, b, e, v, n, t, s) {
                    if (f.fbq) return;
                    n = f.fbq = function() {
                    n.callMethod
                    ? n.callMethod.apply(n, arguments)
                    : n.queue.push(arguments);
                };
                    if (!f._fbq) f._fbq = n;
                    n.push = n;
                    n.loaded = !0;
                    n.version = "2.0";
                    n.queue = [];
                    t = b.createElement(e);
                    t.async = !0;
                    t.src = v;
                    s = b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t, s);
                })(
                    window,
                    document,
                    "script",
                    "https://connect.facebook.net/en_US/fbevents.js",
                    );
                    fbq("init", "1022209267969788");
                    fbq("track", "PageView");`}
        </script>
        <noscript>{`<img height="1" width="1" style="display:none"
                   src="https://www.facebook.com/tr?id=1022209267969788&ev=PageView&noscript=1"/>`}</noscript>
</Helmet>

export default SEO;
