import React from 'react';

import LogoImg from '../assets/i/logo.png';
import LogoCopyrightImg from '../assets/i/logo-copyright.png';
import VkIcon from '../assets/i/icon-vk.png';
import InstIcon from '../assets/i/icon-inst.png';
import FbIcon from '../assets/i/icon-fb.png';
import OkIcon from '../assets/i/icon-ok.svg';
import YtIcon from '../assets/i/icon-yt.svg';

const Footer = () => {
  return (
      <footer className="footer" id="js-footer">
          <div className="footer__inner">
              <div className="footer__copyright">
                  <a href="https://www.foodempire.com/" target="_blank"><img src={LogoCopyrightImg} alt=""/></a>
                  <p>© 2020 Food Empire Ltd. Все права защищены.</p>
              </div>
              <div className="footer__right">
                  <span className="footer__logo"><img src={LogoImg} alt=""/></span>
                  <div className="footer__contacts">
                      <a href="https://vk.com/public182576737" target="_blank"><img src={VkIcon} alt=""/></a>
                      {/*<a href="https://www.instagram.com/_kracks_chips/" target="_blank"><img src={InstIcon} alt=""/></a>*/}
                      <a href="https://www.facebook.com/Kracks-574859359588498/" target="_blank"><img src={FbIcon} alt=""/></a>
                      <a href="https://ok.ru/profile/579171719376" target="_blank"><img src={OkIcon} alt=""/></a>
                      <a href="https://youtube.com/channel/UC6F6I3X1p-xmILLXttp0M-Q" target="_blank"><img src={YtIcon} alt=""/></a>
                  </div>
              </div>
          </div>
      </footer>
  );
};

export default Footer;
